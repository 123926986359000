<template>
  <footer class="footer">
    <div class="footer-wraper">
      <div class="footer-group">
        <div class="footer-group-item logo-box">
          <img src="@/assets/image/home_logo.jpg" alt="" />
        </div>

        <!-- <div class="footer-group-item">
          <div class="footer-group-title">条款与条件</div>

          <ul>
            <li><a href="">Support & FAQ's</a></li>
            <li><a href="">Our Culture</a></li>
            <li><a href="">Sign up</a></li>
            <li><a href="">Team</a></li>
            <li><a href="">Log in</a></li>
            <li><a href="">Terms & Conditions</a></li>
          </ul>
        </div>

        <div class="footer-group-item">
          <div class="footer-group-title">隐私政策</div>

          <ul>
            <li><a href="">About</a></li>
            <li><a href="">Terms & Conditions</a></li>
            <li><a href="">Services</a></li>
            <li><a href="">Our Careers</a></li>
            <li><a href="">Out Products</a></li>
          </ul>
        </div> -->

        <div class="footer-group-item footeritem">
          <div class="footer-group-title">联系我们： 0755-26604981</div>
          <ul class="concat-list">
            <li>公司地址： 深圳市南山区侨香路6060-1香年广场A栋902-2</li>
            <li class="fast-icon-box">
              <div class="fast-icon-link">
                <img src="@/assets/image/icon/Weibo.png" alt="" />
              </div>
              <div class="fast-icon-link">
                <img src="@/assets/image/icon/WeChat.png" alt="" />
              </div>
              <div class="fast-icon-link">
                <img src="@/assets/image/icon/QQ.png" alt="" />
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="copyright-row">
        <p class="copyright">
          银数通系统集成项目金融科技平台 CopyRight 2020-2025
          <a
            style="padding-left: 10px"
            class="tip"
            target="_blank"
            href="https://beian.miit.gov.cn/"
          >
            粤ICP备2020107577号</a
          >
        </p>
        <p class="copyright" style="margin-top: 10px">
          <img src="@/assets/image/icon.png" alt="" />
          <a
            style="padding-left: 10px"
            class="tip"
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502006417"
          >
            粤公网安备 44030502006417号</a
          >
        </p>
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.footer {
  width: 100%;
  //   height: 400px;
  background: #004375;
  color: rgba($color: #ffffff, $alpha: 0.7);
  a {
    color: rgba($color: #ffffff, $alpha: 0.7);
    text-decoration: none;
  }
  .footer-wraper {
    width: $safety-width;
    margin: 0 auto;
    padding: 66px 0 51px 0;
    .footer-group {
      // width: $safety-width;
      display: flex;
      // justify-content: space-between;
      .footeritem {
        padding-left: 300px;
      }
      .footer-group-item {
        ul,
        li {
          list-style: none;
          margin: 0;
          padding: 0;
          font-size: 20px;
          line-height: 20px;
        }

        .footer-group-title {
          // font-size: 20px;
          // margin-bottom: 37px;
          font-size: 24px;
          margin-bottom: 30px;
        }

        .concat-list {
          li {
            line-height: 31px;
          }
        }
        .fast-icon-box {
          margin-top: 20px;
          display: flex;
          .fast-icon-link {
            width: 32px;
            height: 32px;
            margin-right: 20px;
          }
        }
      }

      .logo-box {
        width: 98px;
        height: 98px;
        background: rgba($color: #ffffff, $alpha: 0.7);
        border-radius: 100%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .copyright-row {
      margin-top: 80px;
      .copyright {
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        a.tip:hover {
          cursor: pointer;
          color: red;
        }
      }
    }
  }
}
</style>



