import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Index',
		component: () => import(/* webpackChunkName: "index" */ '../views/index.vue'),
	}, { // 企业搜索
		path: '/company-search',
		name: 'company-search',
		component: () => import(/* webpackChunkName: "company-search" */ '../views/company-search.vue'),
	}, { // 项目搜索
		path: '/project-search',
		name: 'project-search',
		component: () => import(/* webpackChunkName: "project-search" */ '../views/project-search.vue'),
	}, { // 产品列表
		path: '/product-list',
		name: 'product-list',
		component: () => import(/* webpackChunkName: "product-list" */ '../views/product-list.vue'),
	}, {
		path: '/login',
		name: 'login',
		component: () => import(/* webpackChunkName: "login" */ '../views/login.vue'),
	}, {
		path: '/findpwd',
		name: 'findpwd',
		component: () => import(/* webpackChunkName: "findpwd" */ '../views/findpwd.vue'),
	}, {
		path: '/register',
		name: 'register',
		component: () => import(/* webpackChunkName: "register" */ '../views/register'),
	}, {
		path: '/a-register',
		name: 'a-register',
		component: () => import(/* webpackChunkName: "register" */ '../views/register/a-register'),
	}, {
		path: '/b-register',
		name: 'b-register',
		component: () => import(/* webpackChunkName: "register" */ '../views/register/b-register'),
	}, {
		path: '/c-register',
		name: 'c-register',
		component: () => import(/* webpackChunkName: "register" */ '../views/register/c-register'),
	}, {
		path: '/register-success',
		name: 'register-success',
		component: () => import(/* webpackChunkName: "register" */ '../views/register/success'),
	}, {
		path: '/company-detail',
		name: 'company-detail',
		component: () => import(/* webpackChunkName: "company-detail" */ '../views/company-detail.vue'),
	}, {
		path: '/panel-detail/:id',
		name: 'panel-detail',
		component: () => import(/* webpackChunkName: "company-detail" */ '../views/PanelDetail.vue'),
	}, {
		path: '/product-detail/:id',
		name: 'product-detail',
		component: () => import(/* webpackChunkName: "product-detail" */ '../views/product-detail.vue'),
	}, {
		path: '/bid-project-detail',
		name: 'bid-project-detail',
		component: () => import(/* webpackChunkName: "bid-project-detail" */ '../views/bid-project-detail.vue'),
	}, {
		path: '/login-fast',
		name: 'login-fast',
		component: () => import(/* webpackChunkName: "login-fast" */ '../views/login-fast.vue'),
	}, {
		path: '/resetbind',
		name: 'resetbind',
		component: () => import(/* webpackChunkName: "resetbind" */ '../views/resetbind.vue'),
	}, { // 个人中心
		path: '/control',
		name: 'control',
		redirect: 'control/member',
		component: () => import(/* webpackChunkName: "control" */ '@/views/control'),
		children: [
			{
				path: 'member', // 会员续费
				name: 'member-view',
				redirect: 'member/index',
				component: () => import(/* webpackChunkName: "member" */ '@/views/router-view'),
				children: [{
					path: 'index',
					name: 'member-index',
					component: () => import(/* webpackChunkName: "member" */ '@/views/control/member'),
				}, {
					path: 'renew',
					name: 'member-renew',
					component: () => import(/* webpackChunkName: "member" */ '@/views/control/member/renew'),
				}, {
					path: 'history',
					name: 'member-history',
					component: () => import(/* webpackChunkName: "member" */ '@/views/control/member/history'),
				}],
			}, {
				path: 'search', // 集成公司搜索
				name: 'search',
				component: () => import(/* webpackChunkName: "search" */ '@/views/control/search'),
			}, { // b类公司
				path: 'companyb', // 首页
				name: 'companyb',
				redirect: 'companyb/index',
				component: () => import(/* webpackChunkName: "companyb" */ '@/views/router-view'),
				children: [{
					path: 'index',
					name: 'companyb-index',
					component: () => import(/* webpackChunkName: "companyb" */ '@/views/control/b-company/company'),
				}, {
					path: 'info',
					name: 'companyb-info',
					component: () => import(/* webpackChunkName: "companyb" */ '@/views/control/b-company/company-info'),
				}, {
					path: 'edit',
					name: 'companyb-edit',
					component: () => import(/* webpackChunkName: "companyb" */ '@/views/control/b-company/company-edit'),
				}],
			}, {
				path: 'product', // 产品维护
				name: 'product',
				redirect: 'product/index',
				component: () => import(/* webpackChunkName: "product" */ '@/views/router-view'),
				children: [{
					path: 'index',
					name: 'product-index',
					component: () => import(/* webpackChunkName: "product" */ '@/views/control/b-company/product'),
				}, {
					path: 'add',
					name: 'product-add',
					component: () => import(/* webpackChunkName: "product" */ '@/views/control/b-company/product-add'),
				}, {
					path: 'edit',
					name: 'product-edit',
					component: () => import(/* webpackChunkName: "product" */ '@/views/control/b-company/product-edit'),
				}, {
					path: 'info',
					name: 'product-info',
					component: () => import(/* webpackChunkName: "product" */ '@/views/control/b-company/product-info'),
				}],
			}, {
				path: 'bmarketing', // 产品推广宣传
				name: 'bmarketing',
				redirect: 'bmarketing/index',
				component: () => import(/* webpackChunkName: "bmarketing" */ '@/views/router-view'),
				children: [{
					path: 'index',
					name: 'bmarketing-index',
					component: () => import(/* webpackChunkName: "bmarketing" */ '@/views/control/b-company/marketing'),
				}, {
					path: 'add',
					name: 'bmarketing-add',
					component: () => import(/* webpackChunkName: "bmarketing" */ '@/views/control/b-company/marketing-add'),
				}, {
					path: 'edit',
					name: 'bmarketing-edit',
					component: () => import(/* webpackChunkName: "bmarketing" */ '@/views/control/b-company/marketing-edit'),
				}],
			}, { // c类公司
				path: 'companyc', // 首页
				name: 'companyc',
				redirect: 'companyc/index',
				component: () => import(/* webpackChunkName: "companyc" */ '@/views/router-view'),
				children: [{
					path: 'index',
					name: 'companyc-index',
					component: () => import(/* webpackChunkName: "companyc" */ '@/views/control/c-company/company'),
				}, {
					path: 'info',
					name: 'companyc-info',
					component: () => import(/* webpackChunkName: "companyc" */ '@/views/control/c-company/company-info'),
				}, {
					path: 'edit',
					name: 'companyc-edit',
					component: () => import(/* webpackChunkName: "companyc" */ '@/views/control/c-company/company-edit'),
				}],
			}, {
				path: 'bid', // 标地维护
				name: 'bid',
				redirect: 'bid/index',
				component: () => import(/* webpackChunkName: "bid" */ '@/views/router-view'),
				children: [{
					path: 'index',
					name: 'bid-index',
					component: () => import(/* webpackChunkName: "companyc" */ '@/views/control/c-company/bid'),
				}, {
					path: 'add',
					name: 'bid-add',
					component: () => import(/* webpackChunkName: "companyc" */ '@/views/control/c-company/bid-add'),
				}, {
					path: 'edit',
					name: 'bid-edit',
					component: () => import(/* webpackChunkName: "companyc" */ '@/views/control/c-company/bid-edit'),
				}, {
					path: 'info',
					name: 'bid-info',
					component: () => import(/* webpackChunkName: "companyc" */ '@/views/control/c-company/bid-info'),
				}],
			}, {
				path: 'cmarketing', // 标地推广宣传
				name: 'cmarketing',
				redirect: 'cmarketing/index',
				component: () => import(/* webpackChunkName: "cmarketing" */ '@/views/router-view'),
				children: [{
					path: 'index',
					name: 'cmarketing-index',
					component: () => import(/* webpackChunkName: "cmarketing" */ '@/views/control/c-company/marketing'),
				}, {
					path: 'add',
					name: 'cmarketing-add',
					component: () => import(/* webpackChunkName: "cmarketing" */ '@/views/control/c-company/marketing-add'),
				}, {
					path: 'edit',
					name: 'cmarketing-edit',
					component: () => import(/* webpackChunkName: "cmarketing" */ '@/views/control/c-company/marketing-edit'),
				}],
			}],
	}, {
		path: '/a-company',
		name: 'a-company',
		component: () => import(/* webpackChunkName: "a-company" */ '@/views/a-company/index.vue'),
		redirect: '/a-company/company-preserve',
		children: [
			{
				path: 'company-preserve',//首页（企业信息）
				name: 'company-preserve',
				component: () => import(/* webpackChunkName: "company-preserve" */ '@/views/a-company/company-preserve/index.vue'),
				redirect: 'company-preserve/view',
				children: [{
					path: 'view',
					name: 'company-preserve-view',
					component: () => import(/* webpackChunkName: "company-preserve-view" */ '@/views/a-company/company-preserve/view.vue'),
				}, {
					path: 'detail/:code',
					name: 'company-preserve-detail',
					component: () => import(/* webpackChunkName: "company-preserve-detail" */ '@/views/a-company/company-preserve/detail.vue'),
				}, {
					path: 'update/:code',
					name: 'company-preserve-update',
					component: () => import(/* webpackChunkName: "company-preserve-update" */ '@/views/a-company/company-preserve/update.vue'),
				}]
			}, {
				path: 'company',//会员中心
				name: 'company',
				redirect: 'company/company-preserve',
				component: () => import(/* webpackChunkName: "company-preserve" */ '@/views/a-company/company/index.vue'),
				children: [
					{
						// 	path: 'bid-project-refund',
						// 	name: 'bid-project-refund',
						// 	redirect: 'bid-project-refund/list',
						// 	component: () => import(/* webpackChunkName: "bid-project-refund" */ '@/views/a-company/bid-project/bid-project-refund/index.vue'),
						// 	children: [{
						// 		path: 'list',
						// 		name: 'bid-project-refund-list',
						// 		component: () => import(/* webpackChunkName: "bid-project-refund-list" */ '@/views/a-company/bid-project/bid-project-refund/list.vue'),
						// 	}, {
						// 		path: 'uploadcert/:id',
						// 		name: 'bid-project-refund-uploadcert',
						// 		component: () => import(/* webpackChunkName: "bid-project-refund-uploadcert" */ '@/views/a-company/bid-project/bid-project-refund/uploadcert.vue'),
						// 	}, {
						// 		path: 'detail/:id',
						// 		name: 'bid-project-refund-detail',
						// 		component: () => import(/* webpackChunkName: "bid-project-refund" */ '@/views/a-company/bid-project/bid-project-refund/detail.vue'),
						// 	}],
						// }, {
						path: 'bank-account-info',
						name: 'bank-account-info',
						component: () => import(/* webpackChunkName: "bank-account-info" */ '@/views/a-company/company/bank-account-info.vue')
					},
					{
						path: 'company-preserve',
						name: 'company-preserve',
						redirect: 'company-preserve/detail',
						component: () => import(/* webpackChunkName: "member" */ '@/views/a-company/company/company-preserve/index.vue'),
						children: [{
							path: 'detail',
							name: 'company-preserve-detail',
							component: () => import(/* webpackChunkName: "company-preserve-detail" */ '@/views/a-company/company/company-preserve/detail.vue'),
						}, {
							path: 'update/:code',
							name: 'company-preserve-update',
							component: () => import(/* webpackChunkName: "company-preserve-update" */ '@/views/a-company/company/company-preserve/update.vue'),
						}]
					}, {
						path: 'member', // 会员续费
						name: 'member',
						component: () => import(/* webpackChunkName: "member" */ '@/views/a-company/company/member/index.vue'),
						redirect: 'member/view',
						children: [{
							path: 'view',
							name: 'member-view',
							component: () => import(/* webpackChunkName: "member" */ '@/views/a-company/company/member/view.vue'),
						}, {
							path: 'renew',
							name: 'member-renew',
							component: () => import(/* webpackChunkName: "member" */ '@/views/a-company/company/member/renew.vue'),
						}, {
							path: 'history',
							name: 'member-history',
							component: () => import(/* webpackChunkName: "member" */ '@/views/a-company/company/member/history.vue'),
						}],
					}],
			}, {
				path: 'bid-project',//投标项目管理（贷款、保证金）
				name: 'bid-project',
				component: () => import(/* webpackChunkName: "bid-project" */ '@/views/a-company/bid-project/index.vue'),
				redirect: 'bid-project/bid-project-preserve',
				children: [
					{
						path: 'bid-project-preserve',
						name: 'bid-project-preserve',
						redirect: 'bid-project-preserve/list',
						component: () => import(/* webpackChunkName: "bid-project-preserve" */ '@/views/a-company/bid-project/bid-project-preserve/index.vue'),
						children: [{
							path: 'list',
							name: 'bid-project-preserve-list',
							component: () => import(/* webpackChunkName: "bid-project-preserve-list" */ '@/views/a-company/bid-project/bid-project-preserve/list.vue'),
						}, {
							path: 'update/:id',// id为投标项目id  id为0的时候是添加
							name: 'bid-project-preserve-update',
							component: () => import(/* webpackChunkName: "bid-project-preserve-update" */ '@/views/a-company/bid-project/bid-project-preserve/update.vue'),
						}, {
							path: 'detail/:id',
							name: 'bid-project-preserve-detail',
							component: () => import(/* webpackChunkName: "bid-project-preserve-list" */ '@/views/a-company/bid-project/bid-project-preserve/detail.vue'),
						}, {
							path: 'loan-application',
							name: 'loan-application',
							redirect: 'loan-application/bid-margin',
							component: () => import(/* webpackChunkName: "loan-application" */ '@/views/a-company/bid-project/bid-project-preserve/loan-application/index.vue'),
							children: [
								{
									path: 'bid-margin', // 招标保证金
									name: 'bid-margin',
									component: () => import(/* webpackChunkName: "bid-margin" */ '@/views/a-company/bid-project/bid-project-preserve/loan-application/bid-margin/index.vue'),
									children: [{
										path: 'list',
										name: 'bid-margin-apply',
										component: () => import(/* webpackChunkName: "bid-margin-list" */ '@/views/a-company/bid-project/bid-project-preserve/loan-application/bid-margin/list.vue'),
									}, {
										path: 'apply/:id',
										name: 'bid-margin-apply',
										component: () => import(/* webpackChunkName: "bid-margin-apply" */ '@/views/a-company/bid-project/bid-project-preserve/loan-application/bid-margin/apply.vue'),
									}, {
										path: 'detail/:id',
										name: 'bid-margin-detail',
										component: () => import(/* webpackChunkName: "bid-margin-detail" */ '@/views/a-company/bid-project/bid-project-preserve/loan-application/bid-margin/detail.vue'),
									}, {
										path: 'uploadcert/:id',
										name: 'bid-margin-uploadcert',
										component: () => import(/* webpackChunkName: "bid-margin-detail" */ '@/views/a-company/bid-project/bid-project-preserve/loan-application/bid-margin/uploadcert.vue'),
									}],
								}, {
									path: 'goods-loan', // 货款贷款
									name: 'goods-loan',
									component: () => import(/* webpackChunkName: "goods-loan" */ '@/views/a-company/bid-project/bid-project-preserve/loan-application/goods-loan/index.vue'),
									children: [{
										path: 'list',
										name: 'goods-loan-apply',
										component: () => import(/* webpackChunkName: "goods-loan-list" */ '@/views/a-company/bid-project/bid-project-preserve/loan-application/goods-loan/list.vue'),
									}, {
										path: 'apply/:id',
										name: 'goods-loan-apply',
										component: () => import(/* webpackChunkName: "goods-loan-apply" */ '@/views/a-company/bid-project/bid-project-preserve/loan-application/goods-loan/apply.vue'),
									}, {
										path: 'detail/:id',
										name: 'goods-loan-detail',
										component: () => import(/* webpackChunkName: "goods-loan-detail" */ '@/views/a-company/bid-project/bid-project-preserve/loan-application/goods-loan/detail.vue'),
									}, {
										path: 'uploadcert/:id',
										name: 'goods-loan-uploadcert',
										component: () => import(/* webpackChunkName: "goods-loan-detail" */ '@/views/a-company/bid-project/bid-project-preserve/loan-application/goods-loan/uploadcert.vue'),
									}],
								}],
						}],
					}],
			}
		]
	},
];

const router = new VueRouter({
	mode: 'history',
	// base: '/',
	base: process.env.BASE_URL,
	linkActiveClass: 'active',
	routes,
	scrollBehavior() {
		return {
			x: 0,
			y: 0,
		};
	},
});

export default router;
