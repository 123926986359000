export default {
    currency(num) {
        // 将数字串转换成带逗号的显示方式
        if (!/^([+\-])?(\d+)(\.\d+)?$/.test(num)) {
            return num;
        }
        let a = RegExp.$1;
        let b = RegExp.$2;
        let c = RegExp.$3;
        let re = new RegExp().compile("(\\d)(\\d{3})(,|$)");
        while (re.test(b)) b = b.replace(re, "$1,$2$3");
        return a + "" + b + "" + c;
    },
    // towan(num) {
    //     if (num < 10000) return num;
    //     return (num / 10000).toFixed(0) + "万";
    // },
}