import axios from './axios'

//  A类企业注册
const registerMemberA = (data) => {
    return axios.post('/member/register', data)
}

// B类企业注册
const registerMemberB = (data) => {
    return axios.post('/member/bregister', data)
}

// C类企业注册
const registerMemberC = (data) => {
    return axios.post('/member/cregister', data)
}

// 验证公司代码是否存在
const companyCodeIsExist = (code) => {
    return axios.post('/member/checkCode/' + code)
}

const getBindPhoneIsFlag = (bidPhone) => {
    return axios.get('/member/getBindPhoneIsFlag/' + bidPhone)
}

// 获取动态验证码(图片)
const getVerifCodeImage = () => {
    return axios.post('/verifcode', {}, {
        responseType: 'blob'
    })
}

// 校验动态验证码(图片)
const checkVerifCode = (code) => {
    return axios.post('/checkVerifCode/' + code)
}


// 获取手机验证码
const sendPhoneCode = (data) => {
    return axios.post('/sms/sendPhoneMessage/' + data.phone)
}

// 校验动态验证码
const checkPhoneCode = (data) => {
    return axios.post('/sms/checkSmsMessage/' + data.code + '/' + data.phone)
}

// 普通登录
const memberLogin = (data) => {
    return axios.post('/member/login', data)
}

// 发送手机信息登录验证码
const sendPhoneLoginCode = (data) => {
    return axios.post('/sms/sendPhoneLogin/' + data.phone)
}

// 发送手机信息登录验证码
const phoneFastLogin = (data) => {
    return axios.post(`/member/loginPhone/${data.code}/${data.phone}/${data.type}`)
}


export default {
    registerMemberA,
    registerMemberB,
    registerMemberC,
    companyCodeIsExist,
    getVerifCodeImage,
    checkVerifCode,
    sendPhoneCode,
    checkPhoneCode,
    memberLogin,
    sendPhoneLoginCode,
    phoneFastLogin,
    getBindPhoneIsFlag,
}