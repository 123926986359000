"use strict";

import axios from "axios";
import Config from '../config';
import Router from '../router/index';

const getToken = () => {
  return (localStorage.user && JSON.parse(localStorage.user)?.token) ?? '';
}

let config = {
  baseURL: Config.apiUrl,
  timeout: 60 * 1000,
  headers: {
    'Content-Type': 'multipart/form-data',
  }
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    config.headers['Authorization'] = getToken();
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  function (response) {
    if (response.data.state == 401) {
      console.log(Router.currentRoute.path)
      Router.push({
        path: '/login-fast',
        query: {
          redirect: Router.currentRoute.path
        }
      })
      return response.data;
    }
    return response.data;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default _axios
