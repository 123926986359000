<template>
  <div id="app">
    <page-header></page-header>
    <router-view></router-view>
    <page-footer></page-footer>
  </div>
</template>

<script>
import PageHeader from "./views/components/pageHeader.vue";
import PageFooter from "./views/components/pageFooter.vue";
export default {
  name: "app",
  components: {
    PageHeader,
    PageFooter,
  },
  methods: {
    logout() {
      let beginTime = 0; //开始时间
      let differTime = 0; //时间差
      window.onunload = function () {
        differTime = new Date().getTime() - beginTime;
        if (differTime <= 1) {
          window.localStorage.removeItem("user");
          this.$router.push({ path: "/login" });
        } else {
          let user = localStorage.user;
          if (user) {
            this.$store.commit("setUserInfo", JSON.parse(user));
          }
        }
      };
      window.onbeforeunload = function () {
        beginTime = new Date().getTime();
      };
    },
    reout() {
      let _this = this;
      var firstTime = new Date().getTime();
      var currentTime = new Date().getTime();
      var timeOut = 15 * 60 * 1000;
      var timeId = window.setInterval(testTime, 5000);
      document.body.addEventListener(
        "mousemove",
        function () {
          firstTime = new Date().getTime(); // reset
        },
        false
      );
      function testTime() {
        currentTime = new Date().getTime();
        if (currentTime - firstTime > timeOut) {
          clearTimeout(timeId);
          _this.$store.commit("setUserInfo", {});
          window.localStorage.removeItem("user");
          window.location.href = "/#/login";
        }
      }
    },
  },
  mounted() {
    this.logout();
    this.reout();
    let user = localStorage.user;
    if (user) {
      this.$store.commit("setUserInfo", JSON.parse(user));
    }
  },
};
</script>
