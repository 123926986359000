<template>
  <header class="header">
    <div class="header-wraper">
      <div class="header-left">
        <img
          src="@/assets/image/home_logo.jpg"
          class="float-logo"
          @click="$router.push('/')"
        />
        <!-- <div class="float-logo"></div> -->
        <div>银数通系统集成项目金融科技平台</div>
      </div>
      <div class="header-right">
        <div v-if="userInfo.name" style="display: flex; align-items: center">
          <el-link
            class="UserNameColor"
            type="primary"
            style="font-size; 18px; margin-right:10px;"
            :underline="false"
            @click="ToIndex"
            >{{ userInfo.name }}，欢迎你！</el-link
          >
          <el-link
            type="primary"
            style="font-size; 18px;"
            :underline="false"
            @click="logout"
            >退出</el-link
          >
        </div>
        <template v-else>
          <div class="ml70">
            <router-link tag="span" to="/login-fast" style="cursor: pointer"
              >登录</router-link
            >
          </div>
          <div>
            <span style="cursor: pointer" @click="joinUs">注册</span>
          </div>
        </template>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  methods: {
    joinUs() {
      this.$router.push("/register");
    },
    logout() {
      localStorage.clear();
      sessionStorage.clear();
      this.$store.commit("setUserInfo", {});
      this.$router.push("/login");
    },
    ToIndex() {
      let user = JSON.parse(localStorage.user);
      if (user.type === 0) {
        this.$router.push("/a-company");
      } else if (user.type === 1) {
        this.$router.push("/control/companyb");
      } else if (user.type === 2) {
        this.$router.push("/control/companyc");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  width: 100%;
  height: 96px;
  line-height: 96px;
  background: #004375;
  color: rgba($color: #ffffff, $alpha: 0.7);
  position: relative;
  .header-wraper {
    width: $safety-width;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .header-left {
      display: flex;
      align-items: center;
      .float-logo {
        width: 50px;
        height: 50px;
        background: #d8d8d8;
        border-radius: 100%;
        margin-right: 36px;
        background: url(../../assets/image/home_logo.jpg) no-repeat center/cover;
      }
      .float-logo:hover {
        cursor: pointer;
      }
    }
    .header-right {
      display: flex;
      .UserNameColor {
        color: #ddd;
      }
    }

    .ml70 {
      margin-right: 70px;
    }
  }
}
</style>